import React from 'react'
import HomeServices from '../../Home/HomeServices/HomeServices'
import ServiceOverviews from './ServiceOverviews'

const ServiceTypes = () => {
  return (
    <div>
      {/* <HomeServices/> */}
      <ServiceOverviews/>
    </div>
  )
}

export default ServiceTypes
